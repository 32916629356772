import { computed, ref, watch } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useCompList() {
  // Use toast
  const refCompListTable = ref(null)
  const refFeaturedCompTable = ref(null)
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'compNo', label: 'comp id' },
    { key: 'name' },
    { key: 'openForEntry', label: 'Start Date/Time' },
    { key: 'prize', label: 'Prize Pool' },
    { key: 'buyIn', label: 'buy in' },
    { key: 'lateBuyIn', label: 'late buy in' },
    { key: 'status' },
    { key: 'statusComp', label: 'Status comp' },
    { key: 'publishDate', label: 'Public Date/Time' },
    { key: 'action' },
  ]

  const tableFeatureColumns = [
    { key: 'compNo', label: 'comp id' },
    { key: 'name' },
    { key: 'betslips', label: 'No. Of Entries' },
    { key: 'participants', label: 'No. Of Participants' },
    { key: 'date', label: 'Start Date/Time' },
    { key: 'prize', label: 'Prize Pool' },
    { key: 'buyIn', label: 'buy in' },
    { key: 'lateBuyIn', label: 'late buy in' },
    { key: 'status' },
    { key: 'statusComp', label: 'Status comp' },
    { key: 'publishDate', label: 'Public Date/Time' },
    { key: 'action' },
  ]

  const perPage = ref(50)
  const totalComps = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const perPageFeature = ref(100)
  const currentPageFeature = ref(1)
  const totalCompsFeature = ref(0)

  const compTypeOptions = [
    { label: 'All', value: 'All' },
    { label: 'Racing', value: 'classic' },
    { label: 'Sport', value: 'sport' },
    { label: 'Tipping', value: 'sport-tipping' },
  ]

  const currentType = ref('All')

  const dataMeta = computed(() => {
    const localItemsCount = refCompListTable.value ? refCompListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalComps.value,
    }
  })
  const dataMetaFeature = computed(() => {
    const localItemsCount = refFeaturedCompTable.value ? refFeaturedCompTable.value.localItems.length : 0
    return {
      from: perPageFeature.value * (currentPageFeature.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFeature.value * (currentPageFeature.value - 1) + localItemsCount,
      of: totalCompsFeature.value,
    }
  })

  const refetchData = () => {
    refCompListTable.value.refresh()
  }

  watch([currentPage, perPage, currentType], () => {
    refetchData()
  })
  watch([endDateFilter, startDateFilter], () => {
    if (endDateFilter && startDateFilter) { refetchData() }
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalComps.value = 0
      refetchData()
      // eslint-disable-next-line comma-dangle
    }, 700)
  )

  const refetchDataFeature = () => {
    refFeaturedCompTable.value.refresh()
  }

  watch([currentPageFeature, perPageFeature], () => {
    refetchDataFeature()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalComps,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCompListTable,
    isSortDirDesc,
    sortBy,
    refFeaturedCompTable,
    tableFeatureColumns,
    startDateFilter,
    endDateFilter,
    refetchData,

    compTypeOptions,
    currentType,

    perPageFeature,
    currentPageFeature,
    totalCompsFeature,

    dataMetaFeature,
    refetchDataFeature,
  }
}
