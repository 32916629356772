<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

        <b-col>
          <div
            style="font-weight: bold; color: black; font-size: 16px;"
          >
            FLEXI ROLL OVER WALLET: {{ formatCurrencyBuiltInFunc(flexiRollOverWallet) }}
          </div>
        </b-col>

        <b-col
          class="d-flex align-items-end justify-content-end"
        >
          <b-link
            :to="{ name: 'comp-create' }"
          >
            <b-button
              size="sm"
              variant="primary mr-1"
            >
              <span class="ml-25 align-middle">Create race comp</span>
            </b-button>
          </b-link>
          <b-link
            :to="{ name: 'sport-comp-create' }"
          >
            <b-button
              size="sm"
              variant="primary mr-1"
            >
              <span class="ml-25 align-middle">Create sport comp</span>
            </b-button>
          </b-link>
          <b-link
            :to="{ name: 'tipping-comp-create' }"
          >
            <b-button
              size="sm"
              variant="primary mr-1"
            >
              <span class="ml-25 align-middle">Create tipping</span>
            </b-button>
          </b-link>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BLink, BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { numberProp, arrayProp, stringProp } from '@/helpers/props'
import timeFilter from '@core/utils/enum/date'
import { mapState, mapActions } from 'vuex'
import {
  formatCurrencyBuiltInFunc,
} from '@/helpers/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BFormDatepicker,
    // vSelect,
    BButton,
    BLink,
  },
  props: {
    dateFilter: numberProp(),
    dateOptions: arrayProp(),
    dateStartFilter: stringProp(),
    dateEndFilter: stringProp(),
  },

  data() {
    return {
      timeFilter,
      start: '',
      end: '',
    }
  },
  computed: {
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
  },
  watch: {
    dateFilter() {
      this.start = ''
      this.end = ''
    },
  },
  async created() {
    await this.getFlexiRollOverWallet()
  },
  methods: {
    ...mapActions({
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
    }),
  },
  setup() {
    return {
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

<style lang="scss">
button.vs__clear {
    display: none;
}
</style>
