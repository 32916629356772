const timeFilter = {
  TODAY: 1,
  YESTERDAY: 2,
  THIS_WEEK: 3,
  THIS_MONTH: 4,
  THIS_QUARTER: 5,
  THIS_YEAR: 6,
  CUSTOMIZE_TIME: 7,
}

export const calculateDate18YearsAgo = () => {
  const currentDate = new Date()
  currentDate.setFullYear(currentDate.getFullYear() - 18)
  return currentDate.toISOString()
}

export default timeFilter
