<template>
  <div>
    <!-- Filters -->
    <CompFilter />

    <b-card>
      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          FEATURED COMP
        </h4>

        <ChangeListPosition @change-position="handleChangePosition" />
      </div>

      <b-table
        ref="refFeaturedCompTable"
        :items="fetchCompsFeature"
        :fields="tableFeatureColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center text-white my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchCompsFeature.length === 0"
            class="text-center text-white my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(compNo)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              <BLink
                v-if="item.type === 'sport-tipping'"
                :to="{ name: 'tipping-update', params: { id: item.competitionId } }"
              >
                {{ item.competitionId }}
              </BLink>
              <BLink
                v-else
                :to="{ name: 'comp-view', params: { id: item.competitionId } }"
              >
                {{ item.competitionId }}
              </BLink>
            </div>
          </b-media>
        </template>
        <!-- Column: betslips -->
        <template #cell(betslips)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              <div>
                {{ item.participants }}
              </div>
            </div>
          </b-media>
        </template>
        <!-- Column: participants -->
        <template #cell(participants)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              <div>
                {{ item.uniqueParticipants }}
              </div>
            </div>
          </b-media>
        </template>
        <!-- Column: Start Date/Time -->
        <template #cell(date)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.openForEntry, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- Column: Prize Pool -->
        <template #cell(prize)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              style="flex-direction: column;"
            >
              {{ item.totalPrize ? `$${(item.totalPrize + item.totalEntryPrize).toFixed(2)}` : '' }}
              <span style="text-align: center;color: red;"> {{ item.useFlexiRollOverWallet ? '(Use Flexi roll over wallet)' : '' }}</span>
            </div>
          </b-media>
        </template>
        <!-- Column: buy in -->
        <template #cell(buyIn)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.price ? `$${item.price}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- Column: late buy in -->
        <template #cell(lateBuyIn)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.latePrice ? `$${item.latePrice}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              @click="formatStatus(item)"
            >
              {{ formatStatus(item) }}
            </div>
          </b-media>
        </template>
        <!-- Column: statusComp -->
        <template #cell(statusComp)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="formatColorType(item.status)"
            >
              {{ item.status }}
            </b-badge>
          </b-media>
        </template>
        <!-- Column: publishDate -->
        <template #cell(publishDate)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.publishDate, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(action)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal('unset-feature', item.competitionId)">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50"> Unset feature comp </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMetaFeature.from }} to {{ dataMetaFeature.to }} of {{ dataMetaFeature.of }} showdowns
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPageFeature"
              :total-rows="totalCompsFeature"
              :per-page="perPageFeature"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>showdowns</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-2">Sort Type</label>
            <v-select
              style="width: 200px;"
              :value="currentType"
              :options="compTypeOptions"
              :reduce="val => val.value"
              @input="onChangeType"
            />
          <!-- <v-select
              v-modal="currentType"
              :options="compTypeOptions"
              style="width: 200px;"
              :reduce="option => option.value"
              :clearable="false"
            /> -->
          </b-col>

        </b-row>
        <b-row class="mt-2">

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 pr-2 d-flex align-items-center justify-content-center"
          >
            From:
            <b-form-datepicker
              id="startDateFilter"
              v-model="startDateFilter"
              class="mx-2"
              locale="en-US"
              :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
              placeholder="Start date"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 pr-2 d-flex align-items-center justify-content-center"
          >
            To:
            <b-form-datepicker
              id="endDateFilter"
              v-model="endDateFilter"
              class="mx-2"
              locale="en-US"
              :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
              placeholder="End date"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCompListTable"
        :items="fetchComps"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center text-white my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchComps.length === 0"
            class="text-center text-white my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(compNo)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              <BLink
                v-if="item.type === 'sport-tipping'"
                :to="{ name: 'tipping-update', params: { id: item.competitionId } }"
              >
                {{ item.competitionId }}
              </BLink>
              <BLink
                v-else
                :to="{ name: 'comp-view', params: { id: item.competitionId } }"
              >
                {{ item.competitionId }}
              </BLink>
            </div>
          </b-media>
        </template>
        <!-- Column: Start Date/Time -->
        <template #cell(openForEntry)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.openForEntry, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- Column: Prize Pool -->
        <template #cell(prize)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              style="flex-direction: column;"
            >
              {{ item.totalPrize ? `$${(item.totalPrize + item.totalEntryPrize).toFixed(2)}` : '' }}
              <span style="text-align: center;color: red;"> {{ item.useFlexiRollOverWallet ? '(Use Flexi roll over wallet)' : '' }}</span>
              <span style="text-align: center;color: red;"> {{ item.isJackpotEnabled ? '(Enable Jackpot)' : '' }}</span>
            </div>
          </b-media>
        </template>
        <!-- Column: buy in -->
        <template #cell(buyIn)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.price ? `$${item.price}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- Column: late buy in -->
        <template #cell(lateBuyIn)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.latePrice ? `$${item.latePrice}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
            >
              {{ formatStatus(item) }}
            </div>
          </b-media>
        </template>

        <!-- Column: statusComp -->
        <template #cell(statusComp)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="formatColorType(item.status)"
            >
              {{ item.status }}
            </b-badge>
          </b-media>
        </template>
        <!-- Column: publishDate -->
        <template #cell(publishDate)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.publishDate, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal('feature', item.competitionId)">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50"> Set feature comp </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showModal('delete', item.competitionId)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showModal('edit', item.competitionId, item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showModal('copy', item.competitionId)">
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50"> Duplicate Comp</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.status === 'active'"
              @click="showModal('unpublic', item.competitionId, item)"
            >
              <feather-icon

                icon="LockIcon"
              />

              <span class="align-middle ml-50">UnPublic Comp</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.status === 'draft'"
              @click="showModal('public-immediate', item.competitionId, item)"
            >
              <feather-icon

                icon="UnlockIcon"
              />

              <span class="align-middle ml-50">Publish Comp - Immediate</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.status === 'draft'"
              @click="showModal('public-schedule', item.competitionId, item)"
            >
              <feather-icon

                icon="UnlockIcon"
              />

              <span class="align-middle ml-50">Publish Comp - Schedule</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} showdowns
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalComps"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @ok="handleSubmitModal"
    >
      <b-card-text style="color: black;">
        <h6>{{ modal.content }}</h6>

        <b-row
          v-if="action.type === 'public-schedule'"
          class="mb-1 mt-2 d-flex align-items-start"
        >
          <b-col
            cols="3"
            class="font-weight-bold"
          >
            <span>PUBLIC DATE/TIME</span>
          </b-col>
          <b-col cols="9">
            <flat-pickr
              id="openDate"
              v-model="openDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
                disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
              }"
            />
          </b-col>
        </b-row>

      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isLoading" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import UserEnum, { UserAction, UserClosed } from '@core/utils/enum/user'
import { avatarText } from '@core/utils/filter'
import {
  formatEventDate, formatDateTimeAustralia, formatTime, formatDate, formatDateSend,
} from '@/helpers/utils'
import {
  // BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import { subDays, subMinutes } from 'date-fns'
import flatPickr from 'vue-flatpickr-component'
import useCompList from './useCompList'
import CompFilter from './CompFilter'
import ChangeListPosition from './ChangeListPosition'

export default {
  components: {
    CompFilter,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // VerifyIcon,
    Spinner,
    BFormDatepicker,
    // ExportCSV,
    ChangeListPosition,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      UserClosed,
      action: {
        type: '',
        id: '',
        item: '',
      },
      modal: {
        title: '',
        content: '',
      },
      UserAction,
      formatDateTimeAustralia,
      openDate: new Date(),
      disableDate: subMinutes(new Date(), 1),
    }
  },

  computed: {
    ...mapState('races/listComp', [
      'listComp',
      'loading',
      'total',
      'loadingDeleteComp',
      'loadingSetFeature',
    ]),
    isLoading() {
      return this.loadingSetFeature || this.loadingDeleteComp
    },
  },
  // async mounted() {
  //   await this.getListFeatureComp({
  //     limit: this.perPage,
  //     page: this.currentPage,
  //     featured: 1,
  //     withDetailFeatured: 1,
  //   })
  // },
  methods: {
    ...mapActions({
      getListComp: 'races/listComp/getListComp',
      getListFeatureComp: 'races/listComp/getListFeatureComp',
      setFeatureComp: 'races/listComp/setFeatureComp',
      unSetFeatureComp: 'races/listComp/unSetFeatureComp',
      deleteComp: 'races/listComp/deleteComp',
      copyComp: 'races/listComp/copyComp',
      publicComp: 'races/listComp/publicComp',
      setPublicCompSchedual: 'races/listComp/setPublicCompSchedual',
    }),
    formatColorType(val) {
      if (val === 'active') return 'light-success'
      if (val === 'draft') return 'light-danger'
      return 'light-warning'
    },
    async fetchComps() {
      const data = await this.getListComp({
        limit: this.perPage,
        page: this.currentPage,
        sortFIeld: this.sortBy ? this.sortBy : null,
        sortValue: this.sortBy ? (this.isSortDirDesc ? 'asc' : 'desc') : null,
        ...(this.startDateFilter && this.endDateFilter && { createdTimeFrom: this.startDateFilter }),
        ...(this.endDateFilter && this.endDateFilter && { createdTimeTo: this.endDateFilter }),
        ...(this.currentType !== 'All' && { type: this.currentType }),

      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching comp list')
      this.totalComps = this.total

      return data
    },

    async fetchCompsFeature() {
      const data = await this.getListFeatureComp({
        limit: this.perPageFeature,
        page: this.currentPageFeature,
        featured: 1,
        withDetailFeatured: 1,
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching comp list')
      this.totalCompsFeature = data.pagination.total

      return data.data
    },

    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type, id, item) {
      this.action = { id, type, item }
      if (type === 'edit') {
        if (item.type === 'sport-tipping') return this.$router.push({ name: 'tipping-update', params: { id: item.competitionId } })
        return this.$router.push({ name: 'comp-update', params: { id: item.competitionId } })
      }
      if (type === 'feature') {
        this.setModal('Set feature comp', 'Are you sure you want to set this one is featured competition?')
      }
      if (type === 'delete') {
        this.setModal('Delete Competition', 'Are you sure you want to delete this competition?')
      }
      if (type === 'unset-feature') {
        this.setModal('Unset feature comp', 'Are you sure you want to unset this featured competition?')
      }

      if (type === 'copy') {
        this.setModal('Duplicate Comp', `Are you sure you want to duplicate Competition ${id}?`)
      }
      if (type === 'public-immediate') {
        if (item.status === 'draft') {
          this.setModal('Public Comp', `Are you sure you want to public immediately Competition ${id}?`)
        }
      }
      if (type === 'public-schedule') {
        if (item.status === 'draft') {
          this.setModal('Public Comp', `Are you sure you want to public Competition ${id}, please select the time before click SUBMIT?`)
          if(item.publishDate) this.openDate = item.publishDate
        }
      }
      if (type === 'unpublic') {
        if (item.status === 'active') {
          this.setModal('UnPublic Comp', `Are you sure you want to unpublic Competition ${id}?`)
        }
      }

      this.$refs.modal.show()
    },

    async handleSubmitModal(event) {
      event.preventDefault()
      const { id, type, item } = this.action
      if (type === 'feature') {
        const res = await this.setFeatureComp(id)
        if (!res) return
        if (!this.loadingSetFeature) {
          this.$nextTick(() => {
            this.refetchDataFeature()
            this.$refs.modal.hide()
            this.showToastSuccess('Success', 'Set featured competition is successfully!')
          })
        }
      }
      if (type === 'unset-feature') {
        const res = await this.unSetFeatureComp(id)
        if (!res) return
        if (!this.loadingSetFeature) {
          this.$nextTick(() => {
            this.refetchDataFeature()
            this.$refs.modal.hide()
            this.showToastSuccess('Success', 'Unset featured competition is successfully!')
          })
        }
      }
      if (type === 'delete') {
        const res = await this.deleteComp(id)
        if (!res) {
          this.showToastError('Error', 'Delete Competition failed!')
          return
        }
        if (!this.loadingDeleteComp) {
          this.$nextTick(() => {
            this.refetchData()
            this.$refs.modal.hide()
            this.showToastSuccess('Success', 'Delete Competition successfully!')
          })
        }
      }

      if (type === 'copy') {
        const res = await this.copyComp(id)
        if (!res) {
          this.showToastError('Error', 'Duplicate Competition failed!')
          return
        }
        if (!this.loadingDeleteComp) {
          this.$nextTick(() => {
            this.refetchData()
            this.$refs.modal.hide()
            this.showToastSuccess('Success', 'Duplicate Competition successfully!')
          })
        }
      }

      if (type === 'unpublic') {
        if (item.status === 'active') {
          const res = await this.publicComp({
            competitionId: id,
            status: 'draft',
          })
          if (!res) {
            this.showToastError('Error', 'UnPublic Competition failed!')
            return
          }
          if (!this.loadingDeleteComp) {
            this.$nextTick(() => {
              this.refetchData()
              this.$refs.modal.hide()
              this.showToastSuccess('Success', 'UnPublic Competition successfully!')
            })
          }
        }
      }

      if (type === 'public-immediate') {
        if (item.status === 'draft') {
          await this.setPublicCompSchedual({
            competitionId: id,
            publishDate: null,
          })
          const res = await this.publicComp({
            competitionId: id,
            status: 'active',
          })
          if (!res) {
            this.showToastError('Error', 'Public Competition failed!')
            return
          }
          if (!this.loadingDeleteComp) {
            this.$nextTick(() => {
              this.refetchData()
              this.$refs.modal.hide()
              this.showToastSuccess('Success', 'Public Competition successfully!')
            })
          }
        }
      }

      if (type === 'public-schedule') {
        if (item.status === 'draft') {
          const res = await this.setPublicCompSchedual({
            competitionId: id,
            publishDate: this.formatDateSend(this.openDate),
          })
          if (!res) {
            this.showToastError('Error', 'Public Competition failed!')
            return
          }
          if (!this.loadingDeleteComp) {
            this.$nextTick(() => {
              this.refetchData()
              this.$refs.modal.hide()
              this.showToastSuccess('Success', 'Set schedule to public Competition successfully!')
            })
          }
        }
      }
    },
    formatStatus(item) {
      const now = new Date()
      const openForEntry = new Date(item.openForEntry)
      const afterNoonLockout = new Date(item.afternoonLockout)
      const endDate = new Date(item.endDate)
      if (item.status === 'abandon') return 'Abandoned'
      if (now < openForEntry) return 'Not Open'
      if (now >= openForEntry && now < afterNoonLockout) return 'Open'
      if (now >= afterNoonLockout && now < endDate) return 'Lockout'
      if (now >= endDate) return 'End'
      return ''
    },
    onChangeType(selected) {
      this.currentType = selected
    },
    handleChangePosition() {
      this.refetchDataFeature()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const roleId = UserEnum.UserRoleEnum.SuperAdmin
    const {
      tableColumns,
      perPage,
      currentPage,
      totalComps,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refCompListTable,
      sortBy,
      refFeaturedCompTable,
      tableFeatureColumns,
      startDateFilter,
      endDateFilter,
      refetchData,

      compTypeOptions,
      currentType,

      perPageFeature,
      currentPageFeature,
      totalCompsFeature,

      dataMetaFeature,
      refetchDataFeature,
    } = useCompList()

    return {
      getUserData,
      roleId,
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalComps,
      dataMeta,
      perPageOptions,
      searchQuery,
      refCompListTable,
      isSortDirDesc,
      sortBy,
      refetchData,
      // Filter
      avatarText,

      showToastError,
      showToastSuccess,

      isRoleSuperAdmin,
      formatEventDate,

      refFeaturedCompTable,
      tableFeatureColumns,
      startDateFilter,
      endDateFilter,

      formatTime,
      formatDate,
      formatDateSend,

      compTypeOptions,
      currentType,

      perPageFeature,
      currentPageFeature,
      totalCompsFeature,

      dataMetaFeature,
      refetchDataFeature,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
